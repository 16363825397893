<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <div class="bread hidden-sm-and-down">
      <span>当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in lists" :key="item.path">{{
          item.meta.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
export default {
  data() {
    return {
      title: this.$router.currentRoute.meta.title,
      lists: [],
    }
  },
  created() {
    this.onGetBread(this.$router.currentRoute)
  },
  components: {},
  computed: {},
  methods: {
    onGetBread(to) {
      let matched = to.matched //this.$route.matched
      //这个地方是为了固定第一级目录是首页不变
      if (matched.length && matched[0].name !== 'home') {
        matched = [...matched]
      }
      this.lists = matched
    },
  },
  watch: {
    $route(to) {
      this.title = to.meta.title
      this.onGetBread(to)
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  .bread {
    display: flex;
    align-items: flex-end;
    span {
      font-size: 14px;
      color: #606266;
    }
    .el-breadcrumb {
      line-height: normal;
    }
    /deep/ .el-breadcrumb__inner.is-link {
      font-weight: normal;
      color: #606266;
    }
  }
}
h2 {
  padding-top: 20px;
}
</style>
