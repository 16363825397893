<template>
  <div class="carousel-container">
    <div class="contact-container">
      <h1>CONTACT US</h1>
      <div class="box">
        <div class="info">
          <span>地址：</span>
          <span>{{ info.address }}</span>
        </div>
        <div class="info">
          <span>联系电话：</span>
          <span>{{ info.mobile }}</span>
        </div>
        <div class="info">
          <span>电子邮箱：</span>
          <span>{{ info.email }}</span>
        </div>
      </div>
    </div>
    <div class="copyright">
      <span>
        版权所有：{{ info.corporation }} 备案号：{{ info.filingNo }}
      </span>
      <div>
        <img
          style="margin-right: 4px"
          src="@/assets/images/police.png"
          alt=""
        />
        <a target="_blank" :href="info.filingPlatform">
          <span>粤公网安备 {{ info.securityNo }}号</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getWebsiteInfo } from '@/api/index.js'
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.onGetWebsiteInfo()
  },
  components: {},
  computed: {},
  methods: {
    onGetWebsiteInfo() {
      getWebsiteInfo().then((res) => {
        this.info = res.data.info
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/images/contactBg.png') center center fixed
    no-repeat;
  background-size: cover;
  .contact-container {
    position: absolute;
    top: 50%;
    width: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    text-align: left;
    padding: 50px 50px;
    h1 {
      font-size: 60px;
      font-weight: 900;
      color: #fff;
    }
    .box {
      box-sizing: border-box;
      width: 100%;
      height: 300px;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding: 50px 50px;
      span {
        display: block;
        color: #000;
        font-weight: 500;
      }
    }
  }
  .copyright {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 5px;
    color: #000;
    font-size: 10px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: #000;
    }
  }
}
</style>
