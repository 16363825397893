<template>
  <div class="carousel-container">
    <div class="news-center">
      <div class="title">
        <h1>NEWS CENTER</h1>
        <el-button type="text" @click="toNews">更多>></el-button>
      </div>
      <div class="detail">
        <el-row>
          <el-col
            v-for="item in news"
            :key="item.id"
            :xs="24"
            :sm="12"
            :md="8"
            :lg="6"
          >
            <div
              class="news-detail"
              @click="$router.push('/article/' + item.id)"
            >
              <img
                :src="item.cover"
                alt=""
                height="100%"
                width="100%"
                style="display: flex; object-fit: contain"
              />
              <div class="title-box">
                <div class="flex-text">{{ item.title }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object },
  },
  data() {
    return {
      news: {},
    }
  },
  created() {},
  components: {},
  computed: {},
  methods: {
    toNews() {
      this.$router.push('/news')
    },
  },
  watch: {
    value: function (newValue) {
      this.news = newValue.news
    },
  },
}
</script>
<style lang="scss" scoped>
.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/images/newsBg.png') center center fixed no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  .news-center {
    width: 80%;
    height: 100%;
    max-width: 1200px;
    text-align: left;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      /deep/ .el-button {
        color: #fff;
      }
      h1 {
        font-size: 60px;
        font-weight: 900;
        color: #fff;
      }
    }
    .detail {
      overflow-y: auto;
      min-height: 350px;
      max-height: 55%;
      .news-detail {
        position: relative;
        cursor: pointer;
        margin: 0 0 5px 5px;
        height: 300px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        .title-box {
          box-sizing: border-box;
          height: 60px;
          width: 100%;
          padding: 0 20px;
          background-color: rgba($color: #000000, $alpha: 0.7);
          position: absolute;
          bottom: 0;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          .flex-text {
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
          }
        }
      }
      .news-detail:hover {
        background-color: rgba($color: #fff, $alpha: 0.1);
        .title-box {
          height: 120px;
          line-height: 30px;
          .flex-text {
            white-space: normal;
          }
        }
      }
    }
  }
}
</style>
