<template>
  <div>
    <ArticleList :type="type" />
  </div>
</template>

<script>
import ArticleList from '@/components/ArticleList.vue'
export default {
  data() {
    return {
      type: 2,
    }
  },
  created() {},
  components: { ArticleList },
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped></style>
