<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>文档类别</span>
      </div>
      <el-button
        class="docType"
        type="text"
        v-for="o in docType"
        :key="o.title"
        @click="$router.push(o.path)"
      >
        {{ o.title }}
      </el-button>
    </el-card>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>推荐资讯</span>
      </div>
      <div></div>
    </el-card>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>人气排行</span>
      </div>
      <div v-for="(item, index) in ranking" :key="item.id" class="ranking">
        <div class="info" @click="goToArticle(item.id)">
          <div
            class="num"
            :style="
              index === 0
                ? { backgroundColor: 'red' }
                : index === 1
                ? { backgroundColor: 'orange' }
                : index === 2
                ? { backgroundColor: 'burlywood' }
                : {}
            "
          >
            {{ index + 1 }}
          </div>
          <span class="title">{{ item.title }}</span>
        </div>
        <span>{{ item.gmtCreate.split(' ')[0] }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getRanking } from '@/api/media'

export default {
  data() {
    return {
      docType: [
        { title: '新闻媒体', path: '/news' },
        { title: '解决方案', path: '/solution' },
      ],
      ranking: [],
    }
  },
  created() {
    this.onGetRanking()
  },
  components: {},
  computed: {},
  methods: {
    onGetRanking() {
      getRanking().then((res) => {
        this.ranking = res.data.ranking
      })
    },
    goToArticle(id) {
      this.$router.push(`/article/${id}`)
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-card {
  font-weight: 400;
  border: none;
  .el-card__header {
    color: #8a8a8a;
    padding: 0 0;
    display: flex;
    justify-content: flex-start;
  }
  .el-card__body {
    padding: 10px 0;
  }
  .docType {
    display: flex;
    margin: 0;
    padding: 5px 0;
    font-size: 15px;
  }
}
.ranking:hover {
  cursor: pointer;
  color: #3c7dc3;
}
.ranking {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
  span {
    font-size: xx-small;
  }
  .info {
    display: flex;
    align-items: center;
    width: 85%;
    .title {
      font-size: xx-small;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .num {
      width: 18px;
      height: 18px;
      font-weight: bold;
      color: #fff;
      background-color: steelblue;
      font-size: xx-small;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
