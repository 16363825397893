<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getUUID } from './utils/uuid'
import { addView } from '@/api/index'
export default {
  data() {
    return {}
  },
  created() {
    this.onRecordViews()
  },
  computed: {},
  methods: {
    // 记录网站浏览量
    onRecordViews() {
      let userId = localStorage.getItem('userId')
      if (userId) {
        addView(userId)
      } else {
        userId = localStorage.setItem('userId', getUUID())
        addView(userId)
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
