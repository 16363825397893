<template>
  <div>
    <div class="content" v-html="recruit.content"></div>
  </div>
</template>

<script>
import { getRecruit } from '../../api/media'

export default {
  data() {
    return {
      recruit: {},
    }
  },
  created() {
    this.onGetRecruit()
  },
  components: {},
  computed: {},
  methods: {
    onGetRecruit() {
      getRecruit().then((res) => {
        console.log(res.data.info)
        this.recruit = res.data.info
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  text-align: left;
}
</style>
