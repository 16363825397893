<template>
  <div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="article">
          <h3 @click="$router.push(`/article/${item.id}`)">{{ item.title }}</h3>
          <div class="detail">
            <img
              :src="item.cover"
              alt=""
              width="150"
              height="100"
              style="object-fit: cover"
            />
            <div class="detail-info">
              <span>{{ item.summary }}</span>
              <div class="other-info">
                <i class="el-icon-date"></i> <span>{{ item.gmtCreate }}</span>
                <i class="el-icon-view"></i> <span>{{ item.view }}次浏览</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="pagination">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :page-size="params.limit"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getArticles } from '@/api/media'
export default {
  props: { type: { type: Number } },
  data() {
    return {
      list: [],
      total: 0,
      params: {
        page: 1,
        limit: 10,
        type: this.type,
      },
    }
  },

  components: {},
  computed: {},
  created() {
    this.onGetArticles()
  },
  methods: {
    onGetArticles() {
      getArticles(this.params).then((res) => {
        this.list = res.data.page.list
        this.total = res.data.page.total
      })
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.onGetArticles()
    },
  },
}
</script>
<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 20px;
    list-style: none;
    padding-bottom: 24px;
    border-bottom: 2px solid rgba(153, 153, 153, 0.2);
  }
}
.article {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  h3 {
    margin-top: 0;
    font-weight: 900;
    color: #3b8dd1;
  }
  h3:hover {
    cursor: pointer;
    color: #8cac52;
  }
  .detail {
    img {
      float: left;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      overflow: hidden; /** 隐藏超出的内容 **/
      word-break: break-all;
      text-overflow: ellipsis; /** 多行 **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 3; /** 显示的行数 **/
    }
    .detail-info {
      padding-left: 15px;
      height: 100px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .other-info {
        display: flex;
        align-items: center;
        color: #999;
        span {
          font-size: xx-small;
          margin: 0 15px 0 5px;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  margin-bottom: 20px;
}
</style>
