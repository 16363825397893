/**
 * 基于 axios 封装请求模块
 */

import axios from "axios";
import { Message } from "element-ui";

const baseURL = process.env.VUE_APP_BASE_URL
// 创建 axios 实例
const request = axios.create({
  baseURL: baseURL,
  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
}
);

export default request

// 响应拦截器
request.interceptors.response.use(response => {
  let res = response.data;
  if (res.code === 200) {
    return response.data;
  } else {
    Message.error({ message: res.message ? res.message : "系统异常" });
    return Promise.reject(response.data.message);
  }
}, error => {
  if (error.response.data) {
    error.message = error.response.data.message
  }
  Message.error({ message: error.message })
  return Promise.reject(error);
}
);