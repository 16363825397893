import request from '@/utils/request';

/**
 * 添加文章
 */
export const indexArticle = () => {
  return request({
    method: "get",
    url: `/index`
  });
};

/**
 * 获取站点信息
 */
export const getWebsiteInfo = () => {
  return request({
    method: "get",
    url: `/websiteInfo`
  });
};

/**
 * 增加浏览量
 */
export const addView = (userId) => {
  return request({
    method: "put",
    url: `/view/${userId}`
  });
};