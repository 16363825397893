<template>
  <div class="container">
    <vue-particles
      color="#fff"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#fff"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="repulse"
      style="height: 100%"
    >
    </vue-particles>
    <div class="text-container">
      <h1>灵键科技</h1>
      <img src="@/assets/images/1-1.png" alt="" />
      <img src="@/assets/images/2-1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.container {
  background-image: linear-gradient(-180deg, #000 0%, #282c34 100%);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  .text-container {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-size: 180px;
      color: #fff;
      font-weight: 900;
    }
  }
}
</style>
