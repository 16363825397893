<template>
  <div class="header-container">
    <div class="left">
      <h3 class="hidden-sm-and-down">灵键科技</h3>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        router
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/news">新闻媒体</el-menu-item>
        <el-menu-item index="/solution">解决方案</el-menu-item>
      </el-menu>
    </div>
    <div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        router
      >
        <el-menu-item index="/recruit">招贤纳士</el-menu-item>
        <el-menu-item index="/aboutUs">关于我们</el-menu-item>
        <el-menu-item index="/contactUs">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
export default {
  data() {
    return {
      activeIndex: this.$route.path,
    }
  },
  created() {},
  components: {},
  computed: {},
  methods: {},
  watch: {
    $route(to) {
      this.activeIndex = to.path
    },
  },
}
</script>
<style lang="scss" scoped>
.header-container {
  background-color: #212d2d;
  box-sizing: border-box;
  padding: 0 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  .left {
    display: flex;
    align-items: center;
    h3 {
      margin: 0 20px 0 0;
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  /deep/ .el-menu {
    background-color: transparent;
    .el-menu-item {
      color: #fff;
      font-weight: bold;
    }
    .el-menu-item.is-active {
      color: #fff;
      background-color: transparent;
      border-bottom: 4px solid #409eff;
    }
    .el-menu-item:hover {
      color: #fff;
      background-color: #409eff;
    }
  }
}
</style>
