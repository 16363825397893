<template>
  <div class="carousel-container">
    <div class="box">
      <h1>关于灵键</h1>
      <span class="detail">
        &nbsp;&nbsp;&nbsp;&nbsp;
        灵键（佛山南海）科技有限公司，成立于2018年，是一家研发区块链应用、技术以及相关配套设施的公司，集区块链、落地应用、行业解决方案的开发、运营、推广一体。
      </span>
      <span class="detail">
        &nbsp;&nbsp;&nbsp;&nbsp;
        公司拥有未来般的视野，已申报多项发明专利和创新专利。公司以强大的核心技术为驱动，结合用户生活痛点，推出多个接地气的区块链应用，开启无限未来！
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  components: {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.carousel-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/images/aboutUsBg.png') center center fixed
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    height: 300px;
    width: 700px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 0 20px 20px 20px;
    text-align: start;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .detail {
      display: block;
      font-weight: bold;
      letter-spacing: 3px;
    }
  }
}
</style>
