<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  components: {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped></style>
