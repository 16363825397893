<template>
  <div class="aboutUs">
    <div class="content" v-html="aboutUs.content"></div>
  </div>
</template>

<script>
import { getAboutUs } from '../../api/media'
export default {
  data() {
    return {
      aboutUs: {},
    }
  },
  created() {
    this.onGetAboutUs()
  },
  components: {},
  computed: {},
  methods: {
    onGetAboutUs() {
      getAboutUs().then((res) => {
        this.aboutUs = res.data.info
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.aboutUs {
  text-align: left;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 25px;
}
</style>
