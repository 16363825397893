import request from '@/utils/request';

/**
 * 获取新闻媒体、解决方案
 */
export const getArticles = (params) => {
  return request({
    method: "get",
    url: `/articles`,
    params
  });
};

/**
 * 获取详情
 */
export const getArticleDetail = (id) => {
  return request({
    method: "get",
    url: `/article/${id}`,
  });
};

/**
 * 获取上/下一篇文章
 */
export const getPreAndNext = (id, params) => {
  return request({
    method: "get",
    url: `/preAndNext/${id}`,
    params
  });
};

/**
 * 更新文章阅读
 */
export const updateView = (id) => {
  return request({
    method: "put",
    url: `/article/${id}`,
  });
};

/**
 * 获取文章排行
 */
export const getRanking = () => {
  return request({
    method: "get",
    url: `/article/ranking`,
  });
};

/**
 * 获取招聘信息
 */
export const getRecruit = () => {
  return request({
    method: "get",
    url: `/article/recruit`,
  });
};

/**
 * 获取关于我们
 */
export const getAboutUs = () => {
  return request({
    method: "get",
    url: `/article/aboutUs`,
  });
};