<template>
  <div class="carousel-container">
    <div class="solution-container">
      <el-row>
        <el-col :xs="24" :md="8" v-for="item in solution" :key="item.title">
          <div class="info" @click="$router.push('/article/' + item.id)">
            <el-image
              style="width: 100%; height: 200px"
              :src="item.cover"
              fit="contain"
            ></el-image>
            <h3>{{ item.title }}</h3>
            <el-button size="medium">查看详情</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-button type="primary" @click="$router.push('/solution')"
      >查看更多</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object },
  },
  data() {
    return {
      solution: [],
    }
  },

  components: {},
  computed: {},
  methods: {},
  watch: {
    value: function (newValue) {
      this.solution = newValue.solution
    },
  },
}
</script>
<style lang="scss" scoped>
.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/images/solutionBg.png') center center fixed
    no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .solution-container {
    width: 80%;
    height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    .info {
      box-sizing: border-box;
      cursor: pointer;
      margin: 0 10px;
      height: 400px;
      background-color: rgba($color: #000, $alpha: 0.5);
      color: #fff;
      font-weight: bold;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      align-items: center;
      /deep/ .el-button {
        color: #fff;
        background-color: rgba($color: #fff, $alpha: 0);
        width: 100px;
      }
    }
    .info:hover {
      background-color: rgba($color: rgb(11, 31, 80), $alpha: 0.5);
    }
  }
}

@media (max-width: 992px) {
  .solution-container {
    height: 500px !important;
  }
  .info {
    margin: 0 0 10px 0 !important;
  }
}
</style>
