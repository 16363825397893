<template>
  <div class="carousel-container">
    <img
      style="width: 500px"
      src="@/assets/images/FrozenStrawberry.png"
      alt=""
    />
    <div class="description">
      <h3>系统介绍：</h3>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        FrozenStrawberry是一款区块链身份验证系统，把用户的生物信息（面部、指纹、声纹等）作为验证信息，存储在区块链中。用户可在服务端选择性绑定特定的验证信息。利用区块链的存储特性，能有效的保护绑定的验证信息安全。另外架设微服务，提供WEB3接口，供合作伙伴接入到自家项目中。系统主要目的是降低区块链的使用门槛，用户无需知道私钥等概念，即可使用区块链应用；而开放式接口可为其它区块链/网络应用的伙伴提供用户人性化入口。设置O-Key是配合FrozenStrawberry使用的虚拟账户，可以是数字形式（智能便携设备上），也可以是物理硬件（零基础知识的使用门槛）。可同时输出多个不同的验证信息，同时存储记录私钥（物理O-Key可恢复私钥）。目前在智能门锁行业开发了相应的区块链WIFI门锁主控板，形成一个集多重验证、区块链技术、云服务、物联网运用与一体的信息安全平台。
      </span>
      <h3>命名寓意：</h3>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        我们一改以往的科技感，用草莓（strawberry）为我们的系统命名，有创新，勇敢的含义，一个草莓一个人，草莓上的籽是人的验证信息，用冰冻住去保护信息安全。
      </span>
      <h3>产品技术创新点：</h3>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        1.FrozenStrawberry第一次将区块链技术引入到物联网设备上。
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        2.打破了以往人们对身份验证必需的繁琐手续的局限认识。
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3.区块链的安全机制使其对信息的保护保证了数据的一致性和完整性。
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        4.区块链的同步特性能实现各种特殊情况下的数据备份和恢复。
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        5.系统在运行前，会联网检索是否对应最新版本，实现系统自动更新与维护。而现阶段市场上智能门锁多为单一系统，不支持系统的二次更新。
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  components: {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.carousel-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/images/homeBg.jpg') center center fixed no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .description {
    box-sizing: border-box;
    padding: 10px 20px;
    margin-top: 30px;
    width: 70%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: start;
    color: #fff;
    overflow-y: auto;
    h3 {
      margin: 10px;
    }
    span {
      display: block;
      letter-spacing: 2px;
    }
  }
}
</style>
