<template>
  <div class="page-container">
    <div>
      <Header />
      <div class="main-page-container">
        <Bread v-if="showBread" />
        <div class="main-content">
          <router-view class="content"></router-view>
          <Aside class="hidden-sm-and-down" />
        </div>
      </div>
    </div>
    <div><Footer /></div>
  </div>
</template>

<script>
import Header from './header/index.vue'
import Aside from './aside/index.vue'
import Bread from './bread/index.vue'
import Footer from './footer/index.vue'
import 'element-ui/lib/theme-chalk/display.css'
export default {
  data() {
    return {
      showBread: true,
    }
  },

  components: { Header, Aside, Bread, Footer },
  computed: {},
  methods: {},
  watch: {
    $route: {
      handler(newRouter) {
        this.showBread = newRouter.path.indexOf('/article/') !== 0
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.page-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  .main-page-container {
    box-sizing: border-box;
    padding: 0 80px;
  }
  .main-content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .content {
    padding-right: 30px;
  }
}
@media (max-width: 1200px) {
  .main-page-container {
    padding: 0 10px !important;
  }
  .content {
    padding: 0 10px 0 0 !important;
  }
}
@media (max-width: 991px) {
  .content {
    padding: 0 !important;
  }
}
</style>
