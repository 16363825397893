<template>
  <div class="article-container">
    <h2>{{ article.title }}</h2>
    <div class="info">
      <div>
        <i class="el-icon-date"></i> <span>{{ article.gmtCreate }}</span>
      </div>
      <div>
        <i class="el-icon-view"></i> <span>{{ article.view }}次浏览</span>
      </div>
      <div>
        <span>分类：{{ article.type === 1 ? '新闻媒体' : '解决方案' }}</span>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="content" v-html="article.content"></div>
    <div class="next-prev-single">
      <p class="prev">
        上一篇：
        <a v-if="pre != null" :href="'/article/' + pre.id">
          {{ pre.title }}
        </a>
        <a v-else>没有了</a>
      </p>
      <p class="next">
        下一篇：
        <a v-if="next != null" :href="'/article/' + next.id">
          {{ next.title }}
        </a>
        <a v-else>没有了</a>
      </p>
    </div>
    <div class="command">相关资讯</div>
    <div class="information">暂无相关的资讯...</div>
  </div>
</template>

<script>
import { getArticleDetail, getPreAndNext, updateView } from '@/api/media'

export default {
  props: ['id'],
  data() {
    return {
      article: {},
      pre: null,
      next: null,
      params: {
        type: 1,
      },
    }
  },
  created() {},
  components: {},
  computed: {},
  methods: {
    onGetArticle() {
      getArticleDetail(this.id).then((res) => {
        this.article = res.data.detail
      })
    },
    onGetPreAndNext() {
      this.params.type = this.article.type
      getPreAndNext(this.id, this.params).then((res) => {
        this.pre = res.data.pre
        this.next = res.data.next
      })
    },
    onUpdateView() {
      updateView(this.id)
    },
  },
  watch: {
    $route: {
      handler() {
        this.onGetArticle()
        this.onGetPreAndNext()
        this.onUpdateView()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.article-container {
  width: 100%;
  h2 {
    color: #3b8dd1;
    margin: 0 0 12px 0;
    text-align: start;
  }
  .info {
    display: flex;
    font-size: xx-small;
    align-items: center;
    color: rgb(116, 116, 116);
    div {
      margin-right: 20px;
    }
  }
  /deep/ .el-divider {
    width: 100%;
  }
  .content {
    font-size: 14px;
    letter-spacing: 1px;
    text-align: start;
    margin-bottom: 30px;
  }
  .next-prev-single {
    padding: 10px 0;
    color: #444;
    font-size: 14px;
    .prev {
      text-align: start;
      width: 50%;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .next {
      text-align: end;
      width: 50%;
      float: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .command {
    text-align: start;
    border-bottom: 1px solid rgba(153, 153, 153, 0.496);
    color: #4e98d6;
    font-weight: bold;
    font-size: 20px;
  }
  .information {
    text-align: start;
    padding: 20px 0 40px 0;
    font-size: 14px;
    color: #999;
  }
}
</style>
