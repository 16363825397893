<template>
  <div class="footer-container">
    <div class="record">
      <span>版权所有 {{ info.corporation }}</span>
      <span>备案号：{{ info.filingNo }}</span>
    </div>

    <div class="location">
      <span>地址：{{ info.address }}</span>
      <span>电话：{{ info.mobile }}</span>
      <span>邮箱: {{ info.email }}</span>
    </div>
    <div class="location">
      <img style="margin-right: 4px" src="@/assets/images/police.png" alt="" />
      <a target="_blank" :href="info.filingPlatform">
        <span style="color: #fff">粤公网安备 {{ info.securityNo }}号</span>
      </a>
    </div>
  </div>
</template>

<script>
import { getWebsiteInfo } from '@/api/index.js'
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.onGetWebsiteInfo()
  },
  components: {},
  computed: {},
  methods: {
    onGetWebsiteInfo() {
      getWebsiteInfo().then((res) => {
        this.info = res.data.info
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-container {
  background-color: #212d2d;
  width: 100%;
  height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: #fff;
}
.location {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
span {
  margin: 0 10px;
  font-size: 12px;
}
</style>
