import Vue from 'vue'
import VurRouter from 'vue-router'
// 引入新建文件
import Home from '../views/home/index.vue'
import News from '../views/news/index.vue'
import Solution from '../views/solution/index.vue'
import Recruit from '../views/recruit/index.vue'
import AboutUs from '../views/aboutUs/index.vue'
import ContactUs from '../views/contactUs/index.vue'
import Detail from '../views/detail/index.vue'
import Layout from '../layout/index.vue'
Vue.use(VurRouter)

//
const route = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 新闻媒体
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      title: '新闻资讯'
    },
    children: [
      {
        path: '/news',
        name: 'News',
        component: News,
        meta: {
          title: '新闻媒体'
        }
      },
      {
        path: '/solution',
        name: 'Solution',
        component: Solution,
        meta: {
          title: '解决方案'
        }
      },
      {
        path: "/article/:id",
        component: Detail,
        meta: { title: "文章详情" },
        props: true,
      },
    ]
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      title: '公司介绍'
    },
    children: [
      {
        path: '/recruit',
        name: 'Recruit',
        component: Recruit,
        meta: {
          title: '招贤纳士'
        }
      },
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
          title: '关于我们'
        }
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
          title: '联系我们'
        }
      },
    ]
  }
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VurRouter.prototype.push
VurRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const createRouter = () => new VurRouter({
  mode: 'history',
  routes: route
})

const router = createRouter()

export default router

