<template>
  <div>
    <!-- 首页头部 -->
    <div class="header-container">
      <div class="logo hidden-sm-and-down">
        <img src="@/assets/logo.png" alt="" />
        <h3>灵键科技</h3>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in options"
          :key="item.name"
          :label="item.label"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- Banner -->
    <el-carousel
      height="100vh"
      direction="vertical"
      :autoplay="false"
      @change="carouselChange"
      ref="carousel"
    >
      <el-carousel-item
        v-for="item in options"
        :key="item.name"
        :name="item.name"
      >
        <component :is="item.component" :value="info"></component>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Home from './components/home/index.vue'
import AboutUs from './components/aboutUs/index.vue'
import Solution from './components/solution/index.vue'
import News from './components/news/index.vue'
import Media from './components/media/index.vue'
import Product from './components/product/index.vue'
import Contact from './components/contact/index.vue'
import 'element-ui/lib/theme-chalk/display.css'
import { indexArticle } from '@/api'
export default {
  name: 'home',
  components: {
    Home,
    AboutUs,
    Solution,
    News,
    Media,
    Product,
    Contact,
  },
  data() {
    return {
      activeIndex: 0,
      activeName: 'home',
      options: [
        { label: '首页', name: 'home', component: Home },
        { label: '关于我们', name: 'aboutUs', component: AboutUs },
        { label: '解决方案', name: 'solution', component: Solution },
        { label: '新闻媒体', name: 'news', component: News },
        { label: '视频媒体', name: 'media', component: Media },
        { label: '产品介绍', name: 'product', component: Product },
        { label: '联系我们', name: 'contact', component: Contact },
      ],
      info: {},
    }
  },
  created() {
    this.getIndexArticle()
  },
  methods: {
    getIndexArticle() {
      indexArticle().then((res) => {
        this.info = res.data.info
      })
    },
    handleClick(tab) {
      this.$refs.carousel.setActiveItem(this.options[tab.index].name)
    },
    carouselChange(index) {
      this.activeName = this.options[index].name
    },
  },
}
</script>
<style lang="scss" scoped>
.header-container {
  box-sizing: border-box;
  position: absolute;
  height: 80px;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    /deep/ h3 {
      margin: 0 5px;
    }
  }
  /deep/ .el-tabs {
    max-width: 100%;
  }
  /deep/ .el-tabs__item {
    color: #fff;
    height: 80px;
    line-height: 80px;
    font-size: 17px;
  }
  /deep/.el-tabs__nav-wrap::after {
    position: static !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #2c69ff;
    height: 5px;
  }
}
@media (max-width: 992px) {
  .header-container {
    justify-content: center !important;
    /deep/ .el-tabs__nav-prev {
      line-height: 80px;
    }
    /deep/ .el-tabs__nav-next {
      line-height: 80px;
    }
  }
}
</style>
